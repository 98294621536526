<template>
  <LazyZBadge
    v-if="badge && badgeIcon"
    variant="light"
    :class="badge"
  >
    <Fa
      :icon="['fas', badgeIcon]"
      class="mr-1"
    />
    {{ t(badge) }}
  </LazyZBadge>
</template>

<script setup lang="ts">
import type { BADGES } from '~/constants/rv'

const props = defineProps<{
  badge: BADGES
}>()

const { t } = useI18n()

const badgeIcon = computed(() => {
  const iconMap = {
    superhost: 'award-simple',
    instantbook: 'bolt',
    deliveryonly: 'truck-fast',
  }

  return iconMap[props.badge]
})
</script>

<style lang="scss" scoped>
.zbadge {
  &.superhost,
  &.instantbook,
  &.deliveryonly {
    border-radius: 1rem;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 2;
    font-size: 0.75rem;
    padding: 0.1875rem 0.625rem;
    svg {
      font-size: 0.875rem;
      vertical-align: -0.1875rem;
    }
  }

  &.instantbook,
  &.deliveryonly {
    color: getColor('primary-500');
  }

  &.superhost {
    color: getColor('highlight-500');
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "superhost": "Superhost",
    "instantbook": "Instant Book",
    "deliveryonly": "Delivery Only"
  },
  "fr": {
    "superhost": "Superhôtes",
    "instantbook": "Réservation Instantanée",
    "deliveryonly": "Livraison seulement"
  }
}
</i18n>
